<template>
  <div class="mt-4">
    <div class="grey--text text--darken-2 font-weight-black">
      첫 화면 설정
    </div>

    <CrSelect :items="items" :value.sync="mainViewTab" />
  </div>
</template>

<script>
import CrSelect from "@/flow/views/components/commons/select";

export default {
  components: { CrSelect },
  data({ $store: { state } }) {
    const { project } = state?.flowDialog?.params || {};
    return {
      mainViewTab: project?.mainViewTab || "LIST",
      items: [
        { text: "리스트", value: "LIST" },
        { text: "칸반", value: "KANBAN" },
        { text: "간트차트", value: "GANTT" }
      ]
    };
  },
  methods: {
    getData() {
      return { mainViewTab: this.mainViewTab };
    }
  }
};
</script>
